<template>
  <div class="main">
    <img src="@/assets/arrow.svg" :alt="getArrowDescription()" class="back-arrow" @click="backToPlaylist">
    <img @click="backToPlaylist" class="logo-small" src="@/assets/logo_small.svg" alt="Smart Data Logo" />

    <div class="audio-info" :style="{ background: colors[selectedMedia.id - 1] }">
      <img class="corner" src="@/assets/corner.svg">

      <video autoplay preload loop muted playsinline :alt="getVideoDescription()" :key="selectedMedia.id" :poster="getImagePath(selectedMedia.image)">
        <source :src="getAnimationPath(selectedMedia.animation, '.mp4')" type='video/mp4; codecs="hvc1"'>
        <source :src="getAnimationPath(selectedMedia.animation, '.webm')" type="video/webm">
      </video>

      <h4>{{ selectedMedia.title }}</h4>
      <p>{{ getSubtitle() }}</p>
    </div>

    <div class="player">
      <button @click="jumpBack"><img src="@/assets/back.svg" :alt="getBackDescription()"></button>
      <button @click="togglePlayPause">
        <img v-if="isPlaying" src="@/assets/pause.svg" :alt="getPauseDescription()">
        <img v-else src="@/assets/start.svg" :alt="getPlayDescription()">
      </button>
      <button @click="jumpForward"><img src="@/assets/forward.svg" :alt="getForwardDescription()"></button>
    </div>

    <div class="progress">
      <div class="bar-background"></div>
      <div class="progress-bar" :style="{ width: 100 - progressBarWidth + '%' }"></div>

      <span class="start">{{ formattedTime(currentTime) }}</span>
      <span class="end">{{ formattedTime(totalTime) }}</span>
    </div>

    <audio ref="audio" :key="selectedMedia.id" :src="getAudioPath()" @timeupdate="updateProgress"></audio>

    <button class="transcript-button" @click="showTranscriptOverlay()">
      <img class="icon" src="@/assets/transcript.svg">
      {{ getTranscriptButtonText() }}
    </button>

    <div v-if="selectedMedia.id < 15" @click="goToNext(parseInt(selectedMedia.id) + 1)" class="overlay"
      :style="{ background: colors[selectedMedia.id] }">
      <p>{{ getNextButtonText(selectedMedia.stationNumber) }}</p>
      <img class="icon" src="@/assets/next.svg">
      <img class="corner" src="@/assets/corner.svg">
    </div>

    <div v-if="showTranscript" class="transcript">
      <div class="fade"></div>
      <div class="close">
        <button class="close" @click="showTranscript = false"><img src="@/assets/close.svg" alt=""> {{
          getCloseButtonText() }}</button>
      </div>

      <h4> {{ (parseFloat(selectedMedia.stationNumber) >= 5.1 && parseFloat(selectedMedia.stationNumber) <= 5.7) ?
        getSubtitle() : selectedMedia.title }} </h4>
          <p> {{ getTranscript() }} </p>

          <div class="mini-player overlay" @click="togglePlayPause" :style="{ background: colors[selectedMedia.id - 1] }">
            <h5> {{ (parseFloat(selectedMedia.stationNumber) >= 5.1 && parseFloat(selectedMedia.stationNumber) <= 5.7) ?
              getSubtitle(selectedMedia) : selectedMedia.title }} </h5>
                <div>
                  <div class="bar-background"></div>
                  <div class="progress-bar" :style="{ width: progressBarWidth + '%' }"></div>
                </div>

                <button>
                  <img v-if="isPlaying" src="@/assets/pause_min.svg" :alt="getPauseDescription()">
                  <img v-else src="@/assets/start_min.svg" :alt="getPlayDescription()">
                </button>
                <img class="corner" src="@/assets/corner.svg">
          </div>
    </div>
  </div>
</template>

<script>
import mediaList from '@/data/data.json';

export default {
  data() {
    return {
      selectedMedia: null,
      showTranscript: false,
      isPlaying: false,
      currentTime: 0,
      totalTime: 0,
      colors: ['#232323', '#268FFA', '#4988FA', '#6A80FA', '#9079FA', '#B372FA', '#B372FA', '#B372FA', '#B372FA', '#B372FA', '#B372FA', '#B372FA', '#B372FA', '#FA64FA', '#232323'],
    };
  },
  computed: {
    progressBarWidth() {
      if (this.currentTime == null || this.currentTime == 0 ) return 0;
      return (this.currentTime / this.totalTime) * 100;
    },
  },
  created() {
    this.selectedMedia = this.getMediaFromRoute();
  },
  watch: {
    '$route.params.id'(newId, oldId) {
      if (newId !== oldId) {
        this.selectedMedia = this.getMediaFromRoute();
      }
    },
  },
  methods: {
    getMediaFromRoute() {
      const id = parseInt(this.$route.params.id);
      return mediaList.find((media) => media.id == id) || null;
    },

    getAudioPath() {
      //console.log(require('../data/' + this.selectedMedia.srcDE));
      return this.$route.params.language === 'de' ? require('../data/' + this.selectedMedia.srcDE) : require('../data/' + this.selectedMedia.srcEN);
    },

    getImagePath(image) {
      return require('../data/images/' + image);
    },

    getAnimationPath(animation, format) {
      return require('../data/animations/' + animation + format);
    },

    getSubtitle() {
      return this.$route.params.language === 'de' ? this.selectedMedia.subtitleDE : this.selectedMedia.subtitleEN;
    },

    getTranscriptButtonText() {
      return this.$route.params.language === 'de' ? 'Transkript öffnen' : 'Show transcript';
    },

    getTranscript() {
      return this.$route.params.language === 'de' ? this.selectedMedia.transcriptDE : this.selectedMedia.transcriptEN;
    },

    getNextButtonText(stationNumber) {
      if (parseFloat(stationNumber) >= 5 && parseFloat(stationNumber) <= 5.6) {
        return this.$route.params.language === 'de' ? 'Zum nächsten Beitrag' : 'Next entry';
      }
      return this.$route.params.language === 'de' ? 'Zur nächsten Station' : 'Next station';
    },

    getCloseButtonText() {
      return this.$route.params.language === 'de' ? 'Transkript schliessen' : 'Close transcript';
    },

    getArrowDescription() {
      return this.$route.params.language === 'de' ? 'Zurück zur Übersicht' : 'Back to Playlist';
    },

    getVideoDescription() {
      return this.$route.params.language === 'de' ? 'Video der Station mit aufsteigenden Daten-Pixel' : 'Video of the station with ascending data pixels';
    },

    getBackDescription() {
      return this.$route.params.language === 'de' ? '10 Sekunden zurückspringen' : 'Go back 10 seconds';
    },

    getPauseDescription() {
      return this.$route.params.language === 'de' ? 'Audio pausieren' : 'Pause audio';
    },

    getPlayDescription() {
      return this.$route.params.language === 'de' ? 'Audio abspielen/fortsetzen' : 'Play audio';
    },

    getForwardDescription() {
      return this.$route.params.language === 'de' ? '10 Sekunden vorspielen' : 'Go forward 10 seconds';
    },

    jumpBack() {
      this.$refs.audio.currentTime = Math.max(0, this.$refs.audio.currentTime - 10);
    },

    togglePlayPause() {
      const audio = this.$refs.audio;
      
      if (this.isPlaying) {
        audio.pause();
        this.isPlaying = false;
      } else {
        audio.play();
        this.isPlaying = true;
      }
    },

    jumpForward() {
      this.$refs.audio.currentTime = Math.min(this.totalTime, this.$refs.audio.currentTime + 10);
    },

    updateProgress() {
      const audio = this.$refs.audio;
      this.currentTime = audio.currentTime;
      this.totalTime = audio.duration;

      if (this.currentTime >= this.totalTime) {
        this.isPlaying = false;
      }
    },

    formattedTime(seconds) {

      if (isNaN(seconds)) {
        return `0:00`;
      }
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    },

    showTranscriptOverlay() {
      this.showTranscript = true;
      this.scrollToTop();
    },

    backToPlaylist() {
      this.$router.push({ name: 'play-list', params: { language: this.$route.params.language } });
    },

    goToNext(id) {
      this.isPlaying = false;
      this.currentTime = 0;
      this.showTranscript = false;
      this.scrollToTop();
      this.$router.push({ name: 'audio-player', params: { language: this.$route.params.language, id: id } });
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  },
  mounted() {
    this.$refs.audio.addEventListener('timeupdate', this.updateProgress);
    this.$refs.audio.addEventListener('ended', () => {
      this.isPlaying = false;
    });

    this.$refs.audio.addEventListener('loadedmetadata', () => {
      this.totalTime = this.$refs.audio.duration;
    });

    this.scrollToTop();
  },
  beforeUnmount() {
    // Remove event listeners to prevent memory leaks
    this.$refs.audio.removeEventListener('timeupdate', this.updateProgress);
    this.$refs.audio.removeEventListener('ended', () => {this.isPlaying = false; });
    this.$refs.audio.removeEventListener('loadedmetadata', () => {this.totalTime = this.$refs.audio.duration;});
  },
};
</script>
