<!-- StartPage.vue -->
<template>
  <div>
    <img class="logo-small" src="@/assets/logo_small.svg" alt="Smart Data Logo" />
    <h1>Audio Guide</h1>

    <p v-if="$route.params.language == 'de'">Wähle eine Station:</p>
    <p v-else>Select a station:</p>
    <br>

    <div class="entry" v-for="media in mediaList" :key="media.id">
      <router-link :to="{ name: 'audio-player', params: { language: $route.params.language, id: media.id } }">

        <img :src="getPath(media.image)" :alt="getDescription()">
        <div>
          <h2> {{ media.stationNumber }} </h2>
          <h3> {{ (parseFloat(media.stationNumber) >= 5.1 && parseFloat(media.stationNumber) <= 5.7) ? getSubtitle(media) : media.title }} </h3>
        </div>

        <img class="corner" src="@/assets/corner.svg">
        <img src="@/assets/play.svg" alt="Abspielen">
      </router-link>
    </div>

  </div>
</template>

<script>
import mediaList from '@/data/data.json';

export default {
  data() {
    return {
      mediaList,
    };
  },
  methods: {
    getPath(image) {
      return require('../data/images/' + image);
    },

    getSubtitle(media) {
      return this.$route.params.language === 'de' ? media.subtitleDE : media.subtitleEN;
    },

    getDescription() {
      return this.$route.params.language === 'de' ? 'Bild von der Station' : 'Image of Station';
    },
  },
};
</script>
