<template>
  <div>
    <img class="logo-small" src="@/assets/logo_small.svg" alt="Smart Data Logo" />
    <h1>Audio Guide</h1>

    <p class="bold">Wähle deine Sprache:</p>
    <p>Please choose your language:</p>
    <br>
    
    <button @click="setLanguage('de')">Deutsch</button>
    <br>
    <button @click="setLanguage('en')">English</button>
  </div>
  
</template>

<script>
export default {
  methods: {
    setLanguage(language) {
      this.$router.push({ name: 'play-list', params: { language: language } });
    },
  },
};
</script>