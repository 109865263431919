import { createRouter, createWebHistory } from 'vue-router'
import LanguageSelection from '../views/LanguageSelection.vue'
import PlayList from '../views/PlayList.vue'
import AudioPlayer from '../views/AudioPlayer.vue'

const routes = [
  { path: '/', component: LanguageSelection },
  { path: '/:language', name: 'play-list', component: PlayList },
  { path: '/:language/:id', name: 'audio-player', component: AudioPlayer },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
